AOS.init({
    duration: 1000,
    offset: 120,
    easing: 'ease-in-out'
})

document.addEventListener("DOMContentLoaded", function() {
  const scrollContainer = document.querySelector('.search-icons');
  const nextButton = document.querySelector('.scroll-next');

  let isDown = false;
  let startX;
  let scrollLeft;

  scrollContainer.addEventListener('mousedown', (e) => {
    isDown = true;
    scrollContainer.classList.add('active');
    startX = e.pageX - scrollContainer.offsetLeft;
    scrollLeft = scrollContainer.scrollLeft;
    e.preventDefault();
  });

  scrollContainer.addEventListener('mouseleave', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mouseup', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = (x - startX) * 20;
    scrollContainer.scrollLeft = scrollLeft - walk;

    checkScrollPosition(); // Kiểm tra vị trí cuộn khi kéo
  });

  // Kiểm tra vị trí cuộn khi cuộn bằng nút Next
  nextButton.addEventListener('click', function() {
    scrollContainer.scrollBy({
      left: scrollContainer.offsetWidth,
      behavior: 'smooth'
    });

    setTimeout(checkScrollPosition, 500); // Kiểm tra vị trí cuộn sau khi cuộn xong
  });

  // Hàm kiểm tra vị trí cuộn để vô hiệu hóa nút Next
  function checkScrollPosition() {
    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    if (scrollContainer.scrollLeft >= maxScrollLeft) {
      nextButton.classList.add('disabled'); // Vô hiệu hóa nút Next
    } else {
      nextButton.classList.remove('disabled'); // Bật lại nút Next
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  const scrollContainer = document.querySelector('.search-icons-lv1');
  const nextButton = document.querySelector('.scroll-next');

  let isDown = false;
  let startX;
  let scrollLeft;

  scrollContainer.addEventListener('mousedown', (e) => {
    isDown = true;
    scrollContainer.classList.add('active');
    startX = e.pageX - scrollContainer.offsetLeft;
    scrollLeft = scrollContainer.scrollLeft;
    e.preventDefault();
  });

  scrollContainer.addEventListener('mouseleave', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mouseup', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = (x - startX) * 20;
    scrollContainer.scrollLeft = scrollLeft - walk;
  });

  // Kiểm tra vị trí cuộn khi cuộn bằng nút Next
  nextButton.addEventListener('click', function() {
    scrollContainer.scrollBy({
      left: scrollContainer.offsetWidth,
      behavior: 'smooth'
    });
  });
});

document.addEventListener("DOMContentLoaded", function() {
  const scrollContainer = document.querySelector('.sub-category-container');
  const nextButton = document.querySelector('.scroll-next');

  let isDown = false;
  let startX;
  let scrollLeft;

  scrollContainer.addEventListener('mousedown', (e) => {
    isDown = true;
    scrollContainer.classList.add('active');
    startX = e.pageX - scrollContainer.offsetLeft;
    scrollLeft = scrollContainer.scrollLeft;
    e.preventDefault();
  });

  scrollContainer.addEventListener('mouseleave', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mouseup', () => {
    isDown = false;
    scrollContainer.classList.remove('active');
  });

  scrollContainer.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = (x - startX) * 20;
    scrollContainer.scrollLeft = scrollLeft - walk;
  });

  // Kiểm tra vị trí cuộn khi cuộn bằng nút Next
  nextButton.addEventListener('click', function() {
    scrollContainer.scrollBy({
      left: scrollContainer.offsetWidth,
      behavior: 'smooth'
    });
  });
});



